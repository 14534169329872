import React, { useContext } from 'react';
import { bool, string, shape, number } from 'prop-types';
import classnames from 'classnames';
import StaticPropsContext from '../context/static-props';
import Message from '../message';
import getAction from '../../utils/getActionModal';

const namespace = 'ui-vip-shipping-message';

const ShortDescriptionMessage = ({ className, text, type, closeable, hierarchy, primary_action }) => {
  const { deviceType } = useContext(StaticPropsContext);

  return (
    <Message
      className={classnames(namespace, className)}
      actions={primary_action && getAction(primary_action, deviceType, namespace, 'ui-vip-short-description-modal')}
      type={type}
      closeable={closeable}
      hierarchy={hierarchy}
    >
      <div className={`${namespace}__text`}>{text}</div>
    </Message>
  );
};

ShortDescriptionMessage.propTypes = {
  className: string,
  text: string.isRequired,
  type: string,
  closeable: bool,
  hierarchy: string,
  primary_action: shape({
    target: string.isRequired,
    timeout: number,
    duration: number,
    label: shape({
      text: string,
      color: string,
    }),
    icon: shape({
      id: string,
      color: string,
    }),
  }),
};

ShortDescriptionMessage.defaultProps = {
  className: '',
  message_type: 'warning',
  closeable: false,
  hierarchy: 'quiet',
  primary_action: null,
};

export default ShortDescriptionMessage;
