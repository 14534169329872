import React from 'react';
import { Button } from '@andes/button';
import ActionModal from '../components/action-modal';
import Iframe from '../components/iframe';
import IconFactory from '../components/icons/factory-builder';
import onIframeModalResize from '../lib/on-iframe-modal-resize';

const getAction = (action, deviceType, namespace, className = '') => {
  if (deviceType === 'desktop') {
    return (
      <ActionModal
        icon={action.icon ? IconFactory(action.icon, `${namespace}__action-icon`) : null}
        label={action.label}
        url={action.target}
        buttonProps={{
          hierarchy: 'loud',
          size: 'small',
        }}
        modalUrl="#shipping"
        modalClassName={`ui-pdp-iframe-modal ui-pdp-shipping-modal ${className}`}
        autoHeight={false}
        closeModalLabel={action.close_modal_label}
        isOpenModal
      >
        <Iframe
          scrolling="yes"
          src={action.target}
          title={action.label.text}
          onMessage={onIframeModalResize(
            'nav:cp:updateSize',
            'height',
            '.ui-pdp-iframe-modal',
            '.andes-modal__header',
            null,
            deviceType,
            20,
          )}
          height={1000}
        />
      </ActionModal>
    );
  }
  return (
    <div className="ui-pdp-action-modal">
      <Button hierarchy="loud" href={action.target} size="small">
        {action.label.text}
      </Button>
    </div>
  );
};

export default getAction;
